<style lang="scss" scoped>

    .entity-box{
        position: relative;
        width: 1000px;
        margin: 0 auto;
        height: 450px;
    }
    .entity-message {
        /*background-color: #fff;*/
        z-index: 100;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        margin: 0 auto;
        border-radius: 10px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        img {
            width: 220px;
            margin-bottom: 25px;
        }
        p{
            color: #999999;
            font-size: 16px;
        }

    }

</style>
<template>
    <div class="entity-box">
        <div class="entity-message">
            <img :src="imgUrl||defaultUrl">
            <p>{{text || defaultText}}</p>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            let defaultUrl = require("../../assets/images/empty_default.png"),
                defaultText = "暂时没有搜到相关信息";
            return {
                defaultUrl,
                defaultText
            }
        },
        props: [
            "imgUrl",//图片地址
            "text"//显示文字
        ]
    }
</script>


