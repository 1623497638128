<template>
    <el-dialog :visible.sync="visible" title="新增开票">
        <el-form ref="formRef" :model="form" :rules="rules" size="small" label-position="right" label-width="100px">
            <el-form-item label="类型" prop="invoiceType">
                <ApiRadio :options="invoiceTypes" v-model="form.invoiceType"></ApiRadio>
            </el-form-item>
            <el-form-item label="发票抬头" prop="invoiceHead">
                <el-input style="width: 80%" placeholder="请输入发票抬头" v-model="form.invoiceHead"></el-input>
            </el-form-item>
            <el-form-item label="发票金额" prop="invoiceAmount">
                <el-input style="width: 80%" placeholder="请输入发票金额" v-model="form.invoiceAmount">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
            <div v-if="form.invoiceType==='公司'">
                <el-form-item label="税号" prop="invoiceTax">
                    <el-input style="width: 80%" placeholder="请输入税号" v-model="form.invoiceTax"></el-input>
                </el-form-item>
                <el-form-item label="公司地址" prop="companyAddress">
                    <el-input style="width: 80%" placeholder="请输入公司地址" v-model="form.companyAddress"></el-input>
                </el-form-item>
                <el-form-item label="公司电话" prop="companyPhone">
                    <el-input style="width: 80%" placeholder="请输入公司电话" v-model="form.companyPhone"></el-input>
                </el-form-item>
                <el-form-item label="银行名称" prop="bankName">
                    <el-input style="width: 80%" placeholder="请输入银行名称" v-model="form.bankName"></el-input>
                </el-form-item>
                <el-form-item label="银行卡号" prop="bankAccount">
                    <el-input style="width: 80%" placeholder="请输入银行卡号" v-model="form.bankAccount"></el-input>
                </el-form-item>
            </div>
            <el-form-item label="订单编号" prop="orderNo">
                <el-input style="width: 80%" placeholder="请输入订单编号" v-model="form.orderNo"></el-input>
            </el-form-item>
            <el-form-item label="第三方订单ID" prop="thirdInvoiceId">
                <el-input style="width: 80%" placeholder="请输入第三方订单ID" v-model="form.thirdInvoiceId"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input style="width: 80%" placeholder="请输入邮箱" v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" style="width: 80%" placeholder="请输入备注" v-model="form.remark"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button type="primary" :loading="loading" plain @click="visible=false">取消</el-button>
            <el-button type="primary" :loading="loading" @click="handleSubmit">提交开票</el-button>
        </div>
    </el-dialog>
</template>

<script>

import {createInvoiceApi} from "@/www/urls/orderUrls";
import {validateEmail} from "@/common/js/Utils";

export default {
    name: "AddInvoice",
    data() {
        return {
            loading: false,
            visible: false,
            invoiceTypes: [
                {
                    label: '个人',
                    value: 1
                },
                {
                    label: '公司',
                    value: 2
                }
            ],
            form: {},
            rules: {
                invoiceHead: [{required: true, message: "请输入发票抬头"}],
                invoiceAmount: [{required: true, message: "请输入发票金额"}],
                invoiceTax: [{required: true, message: "请输入税号"}],
                email: [{
                    validator: (rule, value, callback) => {
                        if (value && !validateEmail(value))
                            return callback('邮箱格式错误')
                        callback()
                    }, trigger: 'blur'
                }]
            }
        }
    },
    mounted() {
        this.initForm();
    },
    methods: {
        open() {
            this.initForm()
            setTimeout(() => {
                this.$refs['formRef'] && this.$refs['formRef'].clearValidate()
                this.visible = true
            }, 500)
        },
        initForm() {
            this.form = {
                orderNo: '',// 订单编号
                invoiceHead: '',// 抬头
                invoiceTax: '',// 税号
                invoiceType: '个人',// 1 个人 2公司普通
                companyAddress: '',// 公司地址
                companyPhone: '',// 公司电话
                bankName: '',// 公司银行
                bankAccount: '',// 银行卡号
                invoiceAmount: '',// 发票金额
                thirdInvoiceId: '',// 第三方订单id
                email: '',
                remark: '',
                invoiceModel: '1'
            }
        },
        async handleSubmit() {
            try {
                await this.$refs['formRef'].validate()
                await this.$confirm('确认提交开票？', '提示');
                this.loading = true
                const ret = await createInvoiceApi({
                    ...this.form,
                    invoiceType: this.form.invoiceType === '个人' ? 1 : 2
                })
                if (ret.success) {
                    this.$message.success("开票信息提交成功！")
                    this.visible = false
                    this.$emit('onRefresh')
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>


<style scoped lang="scss">

</style>
